import React from 'react';
import { Link, navigate } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/Layout';
import {
    hero,
    featuresSection,
    cover,
    leftSide,
    rightSide,
    action,
    threeBoxes,
    smaller,
    smallerMobile,
    larger,
    itemLeft,
    itemRight,
    mobileCover,
    smallImg,
    section3ItemLeft,
    section3,
    whySection,
} from './features.module.scss';
import { useAppSelector } from '../hooks';
import { getSubdomain } from '../utils/reportUtils';

const Home = () => {
    const subdomain = getSubdomain(true);
    const language = useAppSelector((state) => state.global.language);
    const isSignedIn = useAppSelector((state) => state.global.isSignedIn);
    const isSigningIn = useAppSelector((state) => state.global.isSigningIn);

    React.useLayoutEffect(() => {
        if (subdomain != null && subdomain.length > 1) {
            if (language == null) {
                navigate(`/is/report/`);
            } else {
                navigate(`/${language}/report/`);
            }
        }
    }, [subdomain, language]);

    return (
        <>
            <SEO
                title='Öruggt og traustvekjandi tilkynningakerfi'
                description='Öruggt og traustvekjandi tilkynningakerfi'
            />
            <Layout isFrontpage>
                <div>
                    <section className={hero}>
                        <div>
                            <div className={leftSide}>
                                <div className={mobileCover}>
                                    <img
                                        src='/cover2.jpg'
                                        title=''
                                        alt=''
                                        className={cover}
                                    />
                                </div>
                                <h1>
                                    Öruggt og traustvekjandi tilkynningakerfi
                                </h1>
                                <h2>
                                    Tilkynna er tilkynningakerfi sem einfaldar
                                    uppljóstrunarferlið, tryggir nafnleynd og
                                    einfaldar umsýslu tilkynninga.
                                </h2>
                                <div className={action}>
                                    <Link
                                        className='btn'
                                        to={
                                            isSignedIn || isSigningIn
                                                ? `/account/overview`
                                                : `/is/signup`
                                        }
                                    >
                                        Prófa frítt í 30 daga
                                    </Link>
                                </div>
                            </div>
                            <div className={rightSide}>
                                <img
                                    src='/cover2.jpg'
                                    title=''
                                    alt=''
                                    className={cover}
                                />
                            </div>
                        </div>
                    </section>

                    <section className={whySection}>
                        <div>
                            <h2>
                                Tilkynna getur stuðlað að auknu trausti og
                                gagnsæi þar sem nafnleynd tilkynnanda og öryggi
                                gagna eru tryggð
                            </h2>
                            <div className={threeBoxes}>
                                <div>
                                    <img src='/calendar.svg' alt='Skilvirkni' />
                                    <h3>Skilvirkni</h3>
                                    <p>
                                        Tilkynna einfaldar uppljóstrunarferlið
                                        fyrir tilkynnendur og umsýslu
                                        tilkynninga fyrir lögaðila.
                                    </p>
                                    {/* <p>Tilkynnandi getur óskað eftir nafnleynd sem lögaðila er skylt að virða.</p> */}
                                </div>
                                <div>
                                    <img src='/secure.svg' alt='Öryggi' />
                                    <h3>Öryggi</h3>
                                    <p>
                                        Tilkynningar og samskipti eru dulkóðuð.
                                        Tilkynna uppfyllir kröfur GDPR og
                                        Persónuverndar.
                                    </p>
                                </div>
                                <div>
                                    <img src='/document.svg' alt='Gagnsæi' />
                                    <h3>Gagnsæi</h3>
                                    <p>
                                        Öll gögn og samskipti um tilkynningar
                                        eiga heima á einum stað og eru skráð í
                                        óbreytanlega atburðaskrá.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className={featuresSection}>
                        <div>
                            <h2>Eiginleikar Tilkynna</h2>

                            <div className={itemLeft}>
                                <div className={larger}>
                                    <h3>Lögaðili stofnar aðgang</h3>
                                    <p>
                                        Lögaðili stofnar aðgang að kerfi
                                        Tilkynna sem býr til sérstaka vefslóð
                                        fyrir tilkynnendur. Þar geta starfsmenn
                                        sem vilja tilkynna misferli fyllt inn
                                        upplýsingar varðandi málið. Starfsmaður
                                        getur sent tilkynninguna með nafnleynd
                                        og átt frekari samskipti í gegnum
                                        nafnlausa gátt.
                                    </p>
                                    <p>
                                        Fyllsta öryggis og trúnaðar er gætt við
                                        meðferð og meðhöndlun tilkynninga. Farið
                                        er með allar upplýsingar í samræmi við
                                        grundvallarsjónarmið og reglur um
                                        persónuvernd.
                                    </p>
                                </div>
                                <div className={smaller}>
                                    <img
                                        src='/features/createorganisation.png'
                                        alt=''
                                        className={smallImg}
                                    />
                                </div>
                                <div className={smallerMobile}>
                                    <img
                                        src='/features/createorganisation.png'
                                        alt=''
                                    />
                                </div>
                            </div>

                            <div className={itemRight}>
                                <div className={smaller}>
                                    <img
                                        src='/features/reportareas.png'
                                        alt=''
                                        className={smallImg}
                                    />
                                </div>

                                <div className={larger}>
                                    <h3>Mörg tilkynningasvæði</h3>
                                    <p>
                                        Lögaðili getur stofnað mörg mismunandi
                                        tilkynningasvæði. Það getur hentað
                                        stærri fyrirtækjum eða stofnunum til að
                                        aðgreina í sundur mismunandi hópa eins
                                        og deildir eða verktaka. Hvert
                                        tilkynningarsvæði fær sitt eigið
                                        undirlén, t.d.{' '}
                                        <i>verktakar-fyrirtaeki</i>.tilkynna.is
                                        og <i>starfsmenn-fyrirtaeki</i>
                                        .tilkynna.is.
                                    </p>
                                    <ul>
                                        <li>
                                            Hægt er að breyta og bæta við
                                            spurningum inn á tilkynningarsvæðum.
                                        </li>
                                        <li>
                                            Hægt er að búa til síður fyrir
                                            tilkynningarsvæði eins og
                                            leiðbeiningar, verklagsreglur og
                                            persónuverndarstefnu.
                                        </li>
                                        <li>
                                            Hvert tilkynningasvæði getur haft
                                            mismunandi reglur. Sem dæmi þá er
                                            hægt er að setja lykilorð fyrir
                                            svæði, velja hvaða
                                            persónuupplýsingar óskað er eftir og
                                            hvort auðkenning með rafrænum
                                            skilríkjum í gegnum island.is sé
                                            nauðsynlegt.
                                        </li>
                                    </ul>
                                </div>
                                <div className={smallerMobile}>
                                    <img
                                        src='/features/reportareas.png'
                                        alt=''
                                    />
                                </div>
                            </div>

                            <div className={itemLeft}>
                                <div className={larger}>
                                    <h3>Umsýsla tilkynninga</h3>
                                    <p>
                                        Við umsýslu tilkynninga er hægt að
                                        breyta málsheiti, setja stöðu málsins,
                                        bæta við umsjónaraðilum og skrá
                                        merkimiða. Þar er líka hægt að skrifa
                                        athugasemdir, til dæmis ef teymi innan
                                        lögaðila vill gefa álit á málinu. Einnig
                                        er hægt að eiga samskipti við
                                        tilkynnanda ef óskað er eftir frekari
                                        upplýsingum.
                                    </p>

                                    <ul>
                                        <li>
                                            Lögaðili getur stofnað mismunandi
                                            merkimiða til að auðvelda flokkun á
                                            tilkynningum og til að einfalda leit
                                            að tilkynningum.
                                        </li>
                                        <li>
                                            Lögaðili getur skráð einn eða fleiri
                                            umsjónaraðila á tilkynningu. Þeir fá
                                            tilkynningu í tölvupósti þegar ný
                                            skilaboð eða athugasemdir berast.
                                        </li>
                                        <li>
                                            Lögaðili getur hreinsað
                                            persónuupplýsingar úr tilkynningu,
                                            t.d. þegar mál hefur verið afgreitt
                                            og til að uppfylla kröfur
                                            Persónuverndar.
                                        </li>
                                    </ul>
                                </div>
                                <div className={smaller}>
                                    <img src='/features/report.png' alt='' />
                                </div>
                                <div className={smallerMobile}>
                                    <img src='/features/report.png' alt='' />
                                </div>
                            </div>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <Link className='btn large' to={`/features/`}>
                                Lesa um fleiri eiginleika Tilkynna
                            </Link>
                        </div>
                    </section>

                    {/* <section
                        className={section2}
                    >
                        <div>
                            <h2>Tilkynna getur stuðlað að auknu trausti og gagnsæi þar sem nafnleynd tilkynnanda og öryggi gagna eru tryggð</h2>
                            <div className={threeBoxes}>
                                <div>
                                    <img src="/usersecure.svg" alt="Nafnleynd" />
                                    <h3>Nafnleynd</h3>
                                    <p>Tilkynnandi getur óskað eftir nafnleynd sem lögaðila er skylt að virða.</p>
                                </div>
                                <div>
                                    <img src="/secure.svg" alt="Öryggi" />
                                    <h3>Öryggi</h3>
                                    <p>Allar tilkynningar og samskipti eru dulkóðuð og eiga heima á einum stað.</p>
                                </div>
                                <div>
                                    <img src="/documents.svg" alt="Gagnsæi" />
                                    <h3>Gagnsæi</h3>
                                    <p>Allar aðgerðir eru skráðar í atburðaskrá sem ekki er hægt að breyta.</p>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className={section3}>
                        <div>
                            <h2>Lög um vernd uppljóstrara</h2>
                            {/* <div className={`${section3Item}, ${section3ItemLeft}`}>
                                <div className={smallerMobile}>
                                    <img src="/clipboard.svg" alt="" />
                                </div>
                                <div className={larger}>
                                    <h3>Lögaðili stofnar aðgang</h3>
                                    <p>
                                        Lögaðili stofnar aðgang að kerfi Tilkynna sem býr til sérstaka vefslóð fyrir tilkynnendur. Þar geta starfsmenn sem vilja tilkynna misferli fyllt inn upplýsingar varðandi málið. Starfsmaður getur sent tilkynninguna með nafnleynd og átt frekari samskipti í gegnum nafnlausa gátt.
                                    </p>
                                    <p>
                                        Fyllsta öryggis og trúnaðar er gætt við meðferð og meðhöndlun tilkynninga. Farið er með allar upplýsingar í samræmi við grundvallarsjónarmið og reglur um persónuvernd.
                                    </p>
                                </div>
                                <div className={smaller}>
                                    <img src="/clipboard.svg" alt="" />
                                </div>
                            </div>
                            <div className={`${section3Item}, ${section3ItemRight}`} style={{ display: 'none' }}>
                                <div className={smaller}>
                                    <img src="/gavel.svg" alt="" />
                                </div>
                                <div className={larger}>
                                    <h3>Stofnun tilkynninga</h3>
                                    <p>
                                        Sá aðili sem vill stofna tilkynningu fer inn á sérstaka vefslóð lögaðila. Þar gæti hann þurft að auðkenna sig með Ísland.is eða gefið fram aðrar persónuupplýsingar um sig. Lögaðili hefur þann valkost til að velja hvaða upplýsingar hann óskar eftir frá tilkynnanda.
                                    </p>
                                    <p>
                                        Lögaðilar þurfa að setja sér verklagsreglur í samráði við starfsmenn sem kveða m. a. á um móttöku, meðhöndlun og afgreiðslu tilkynninga um misferli í starfsemi lögaðila.
                                    </p>
                                    <a href="https://www.althingi.is/altext/150/s/1405.html" target="_blank" rel="noreferrer">Lesa um lögin á vef Alþingis</a>
                                </div>
                            </div> */}
                            <div className={section3ItemLeft}>
                                <div className={smaller}>
                                    <img src='/gavel.svg' alt='' />
                                </div>
                                <div className={smallerMobile}>
                                    <img src='/gavel.svg' alt='' />
                                </div>
                                <div className={larger}>
                                    <p>
                                        Þann 1. janúar 2021 tóku í gildi lög um
                                        vernd uppljóstrara. Markmið þeirra er að
                                        stuðla að því að upplýst verði um
                                        lögbrot og aðra ámælisverða háttsemi og
                                        þannig dregið úr slíku hátterni.
                                    </p>
                                    <p>
                                        Lögaðilar þurfa að setja sér
                                        verklagsreglur í samráði við starfsmenn
                                        sem kveða m.a. á um móttöku, meðhöndlun
                                        og afgreiðslu tilkynninga um misferli í
                                        starfsemi lögaðila.
                                    </p>
                                    <a
                                        href='https://www.althingi.is/altext/150/s/1405.html'
                                        target='_blank'
                                        className='special'
                                        rel='noreferrer'
                                    >
                                        Lesa um lögin á vef Alþingis
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* <div className={style.aboutContainer}>
                        <div className={style.left}>
                            <div className={style.about}>
                                <h1 className={style.heading}>Öruggt og traustvekjandi uppljóstrunarkerfi</h1>
                                <img src="/cover.jpg" title="" alt="" className={style.mobileImage} />

                                <p>
                                    Að tryggja nafnleynd starfsmanna við uppljóstrun er lykilatriði svo þeir treysti sér til að tilkynna hugsanlegt brot á lögum eða aðra ámælisverða háttsemi. Ólíklegt er að starfsmenn sendi tilkynningar með tölvupósti þar sem þeir vita ekki með vissu hver móttakandi er, eða hvað verður um tölvupóstinn þeirra.
                                </p>
                                <p>
                                    Þjónusta <strong>Tilkynna</strong> getur stuðlað að auknu trausti og gagnsæi þar sem það liggur fyrir fyrirfram hver sér tilkynninguna, og hvernig farið er með upplýsingar viðkomandi.
                                </p>
                                <div className={style.contact}>
                                    <a
                                        className="btn"
                                        href="mailto:info@tilkynna.is"
                                    >
                                        Hafa samband
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </div>
            </Layout>
        </>
    );
};

export default Home;
