// extracted by mini-css-extract-plugin
export var about = "features-module--about--vCWGl";
export var aboutContainer = "features-module--aboutContainer--aGboM";
export var aboutSection = "features-module--aboutSection--QAKRf";
export var action = "features-module--action--3YoZq";
export var cover = "features-module--cover--HpFiA";
export var details = "features-module--details--6WLSD";
export var error = "features-module--error--ZdWoq";
export var featuresSection = "features-module--featuresSection--BL5ru";
export var form = "features-module--form--JOmtQ";
export var hasError = "features-module--hasError---l72D";
export var heading = "features-module--heading--RgHz0";
export var hero = "features-module--hero--OSjww";
export var item = "features-module--item---gZPM";
export var itemLeft = "features-module--itemLeft--SNCKQ";
export var itemRight = "features-module--itemRight--4B5Bu";
export var larger = "features-module--larger--7+Zpz";
export var left = "features-module--left--9s09H";
export var leftSide = "features-module--leftSide--24mqD";
export var mobileCover = "features-module--mobileCover--ITtBI";
export var mobileImage = "features-module--mobileImage--QvH0b";
export var passwordItem = "features-module--passwordItem--Jhdqd";
export var pricingColumn = "features-module--pricingColumn--qJHA1";
export var pricingHeader = "features-module--pricingHeader--Kw1Bf";
export var pricingSection = "features-module--pricingSection--R-LfZ";
export var right = "features-module--right--kcGLu";
export var rightSide = "features-module--rightSide--l3i8E";
export var section3 = "features-module--section3--5x90p";
export var section3ItemLeft = "features-module--section3ItemLeft--snR+U";
export var section3ItemRight = "features-module--section3ItemRight--veL7t";
export var smallImg = "features-module--smallImg--N8Qif";
export var smaller = "features-module--smaller--eykDI";
export var smallerMobile = "features-module--smallerMobile--QGLM8";
export var subpageHero = "features-module--subpageHero--Y-rKp";
export var threeBoxes = "features-module--threeBoxes--SPKX0";
export var twoBoxes = "features-module--twoBoxes--lEN7g";
export var whySection = "features-module--whySection--1bd7g";
export var whySectionOld = "features-module--whySectionOld--Gt21r";