export const getSubdomain = (skipLocal: boolean = false) => {
    const testDomain = 'yay';
    const windowGlobal = typeof window !== 'undefined' && window;
    if (
        windowGlobal == null ||
        windowGlobal.location == null ||
        windowGlobal.location.hostname == null ||
        windowGlobal.location.hostname.length < 2
    ) {
        return null;
    }

    if (!skipLocal && windowGlobal.location.hostname === 'localhost') {
        return testDomain;
    }

    const arr = windowGlobal.location.hostname.split('.tilkynna.is');

    if (arr.length < 2) {
        return null;
    }

    if (!skipLocal && arr[0] === 'local') {
        return testDomain;
    }

    return arr[0];
};
